.addv_active{
    display: flex !important;
  }
  .addv_hive{
    display: none;
  }

  .custom-file-input {
    position: relative !important;
    opacity: 1 !important;
    z-index: 9 !important;
    /* padding: 0; */
    margin: 0 !important;
  }