

/* Fleetp Layout*/

.wrapper-holder {
  position: relative;
  top: 0;
  height: 100vh;
}

.logo img {
  width:20%;
}

.sidebar .logo {
  padding: 15px 0px;
  margin: 0;
  display: block;
  position: relative;
  z-index: 4;
}

.sidebar, .main-panel {
  height: 100%;
  max-height: 100%;
}

.sidebar, .main-panel {
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: .2s, .2s, .35s;
  transition-duration: .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 260px;
  background: #fff;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
}

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - 260px);
  transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
 
  -ms-overflow-style: none;
}
.card-header.card-header-primary{
  text-align: left;
}
/* End Fleetp Layout*/

input.filter-text {
  padding: 3px !important;
  border: 1px solid #cbb8b8 !important;
}
button.print {
  display:none;
}
button.download{
  display:none;
}
.data-table-extensions-filter {
  width:30%;
  float: right;
}
.dropdown{
  float:left
}
.data-table-extensions button{
  color: #333333 !important;
    background-color: #fafafa !important;
    border-color: #ccc !important;
    text-transform: uppercase;
    margin-right: 3px;
    padding: 2px;
    box-shadow: 0 2px 2px 0 rgb(250 250 250 / 14%), 0 3px 1px -2px rgb(250 250 250 / 20%), 0 1px 5px 0 rgb(250 250 250 / 12%);
}

.hbotQl {
  font-weight: bold !important;
  font-size: 14px !important;
}
.WQNxq{
  border:1px solid;
}
.bQdYbR{
  border: 1px solid #cfc4c44d;
}

.datahead{
  font-weight: bold !important;
}
.sc-eDvSVe div:first-child {
  white-space: pre-line !important;
  text-align:left !important
}
.eVPPvF{
  padding-left:2px !important;
  padding-right: 2px !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 999;
  margin-left: 22%;
}
.ReactModal__Content.ReactModal__Content--after-open{
  z-index: inherit;
  width: 70%;
}

.card .card-header {
  z-index: 0!important;
}
.btn-primary{
  color:#fff !important
}
.closebtn{
  background-color: #e7e6e6 !important;
}

.form-control:disabled, .form-control[disabled]{
  border-bottom: 1px solid #d2d2d2 !important;
}

.modalcontainer label{
  color: #000 !important;
  font-weight: 800 !important;

}
.modal-title {
  color: #000 !important;
  font-weight: 800 !important;
}
label.bmd-label-floating {
  color: #000;
}

span.side-navigation-panel-select-inner-option-wrap {
  margin-left: -46px;
}

.view_form{
  color:purple;
  font-weight:bold
}

.fuelreport .hMtjbh{
  padding:3% !important;
}

.boPNbN{
  padding:3% !important;
  font-size:15px !important;
}

.bQdYbR{
  font-size:15px !important;
  padding: 2%;
}

.sidebar{
  overflow: auto;
}

::-webkit-scrollbar {
  width: 5px !important;
  background: #584d4d80;
}

.side-navigation-panel-select-option.side-navigation-panel-select-option-selected{
  background-color: #7f3e98 !important;
    color: #fff !important;
}
.admindash{
  background-color: #7f3e98 !important;
  color: #fff !important;
}

.viewform{
  color:#7f3e98;
  font-weight:bold
}

.closeleaveform{
  background: #ece3e3;
    margin: 3%;
}
.closeleaveform h3{
  text-align: left;
    /* font-size: 22px; */
    font-weight: 600;
}

.warncount{
  color: rgb(255, 255, 255);
    background: red;
    border-radius: 50px;
    width: 15%;
    position: absolute;
    right: -22px;
    top: 0;
    font-size: 13px;
}

span.side-navigation-panel-select-option-wrap{
  position: relative;
}

.kcxRMj{
  padding: 2%;
      border: 1px solid #eae1e1;
  }
  .ckIGkD{
  padding: 2%;
      border: 1px solid #eae1e1;
  }

  /* /////////////////////////////////////////////// */

  .sc-dkrFOg.grhuQN.rdt_TableHeadRow {
    color: #000 !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.goaKXo.cRueID.gqbUTD.rdt_TableCol {
    border: 1px solid #eae1e1 !important;
}


.sc-csuSiG.kHWlsj.rdt_TableCol_Sortable{
color: #000 !important;
    font-weight: bold !important;
    font-size: 14px !important;
}


.sc-eDvSVe div:first-child {
  white-space: pre-line !important;
  text-align:left !important
}


.RJkoq{
display:block !important;
}



.cRueID{
border: 1px solid #eae1e1 !important;
}
.dtqzBx {
  border: 1px solid #f1eded;
}

.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.glyQNg.kJeKzx.dlSNBX.rdt_TableCol {
  border: 1px solid #f1eded;
}
.datahead {
  font-size: 15px;
}
.sc-eDvSVe {
  font-size: 14px;
  color: #000;
}

.select__control.css-1s2u09g-control, .css-1pahdxg-control{
  width: 100% !important;
  /* float: left !important; */
}

.css-1s2u09g-control:hover {
  width: 100% !important;
}
.sc-jSUZER {
  border-right: 2px solid #f1eded !important;
}
.sc-eDvSVe {
  border-right: 1px solid #e0e0e0;
}

.btn-group a {
  padding: 4px 6px;
}
.btn-group{
  display:block !important;
}

.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.goaKXo.dkPdIT.gqbUTD.rdt_TableCol {
  border: 1px solid #eae1e1;
}

.sc-hHTYSt.ewkixa.rdt_TableBody {
  border-left: 2px solid #eae1e1;
}

.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.goaKXo.iLQRKL.gqbUTD.rdt_TableCol {
  border: 1px solid #eae1e1;
}

.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.goaKXo.gAxoSv.gqbUTD.rdt_TableCol {
  border-top: 1px solid #e0e0e0;
}

.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.goaKXo.gPEGOf.gqbUTD.rdt_TableCol {
  border-top: 1px solid #e0e0e0;
}

/* .kEIRkz div:first-child {
  min-width: 1000px;
} */

.sc-hLBbgP {
  border-top: 1px solid #eae1e1;
}

.rdt_Table{
  border: 1px solid !important;
}