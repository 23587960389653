.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pod-holder {
  width: 850px !important;
  margin: 0 auto;
  height: 500px !important;
}

.con-pod{
  margin-top: 12px;
  border-bottom: 1px solid #dce8f1;
  text-align: left;
  font-size: 20px !important;
}

.con-pod h4.card-title {
  font-size: 20px;
}

.md-holder{
  border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 675px;
    position: relative;
    padding: 0px;
    box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%);
    /* text-align: center; */
}