/*!

 =========================================================
 * Material Dashboard rtl - v1.1
 =========================================================
 *
 * author: Alireza Esfahani <aynzad@gmail.com>
 * https://github.com/aynzad
 * http://gonjeshk.ir
 *
 */
html[dir="rtl"] {
  text-align: right;
  direction: rtl;
}

html[dir="rtl"] .nav {
  padding-right: 0;
}

html[dir="rtl"] th {
  text-align: right;
}

html[dir="rtl"] .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem;
}

html[dir="rtl"] .dropdown-menu {
  right: 0;
  text-align: right;
}

html[dir="rtl"] .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

html[dir="rtl"] .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.25rem 0.25rem 0;
}

html[dir="rtl"] .btn-group > .btn:last-child:not(:first-child),
html[dir="rtl"] .btn-group > .dropdown-toggle:not(:first-child) {
  border-radius: 0.25rem 0 0 0.25rem;
}

html[dir="rtl"] .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-radius: 0.25rem 0 0 0.25rem;
}

html[dir="rtl"] .custom-control-label::after,
html[dir="rtl"] .custom-control-label::before {
  right: 0;
  left: inherit;
}

html[dir="rtl"] .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
  background-size: 8px 10px;
}

html[dir="rtl"] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
html[dir="rtl"] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
html[dir="rtl"] .input-group > .input-group-append:not(:last-child) > .btn,
html[dir="rtl"] .input-group > .input-group-append:not(:last-child) > .input-group-text,
html[dir="rtl"] .input-group > .input-group-prepend > .btn,
html[dir="rtl"] .input-group > .input-group-prepend > .input-group-text {
  border-radius: 0 0.25rem 0.25rem 0;
}

html[dir="rtl"] .input-group > .input-group-append > .btn,
html[dir="rtl"] .input-group > .input-group-append > .input-group-text,
html[dir="rtl"] .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
html[dir="rtl"] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
html[dir="rtl"] .input-group > .input-group-prepend:not(:first-child) > .btn,
html[dir="rtl"] .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
}

html[dir="rtl"] .input-group > .custom-select:not(:first-child),
html[dir="rtl"] .input-group > .form-control:not(:first-child) {
  border-radius: 0.25rem 0 0 0.25rem;
}

html[dir="rtl"] .input-group > .custom-select:not(:last-child),
html[dir="rtl"] .input-group > .form-control:not(:last-child) {
  border-radius: 0 0.25rem 0.25rem 0;
}

html[dir="rtl"] .input-group > .custom-select:not(:last-child):not(:first-child),
html[dir="rtl"] .input-group > .form-control:not(:last-child):not(:first-child) {
  border-radius: 0;
}

html[dir="rtl"] .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem;
}

html[dir="rtl"] .custom-control-indicator {
  right: 0;
  left: inherit;
}

html[dir="rtl"] .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: .25rem 0 0 .25rem;
}

html[dir="rtl"] .radio input,
html[dir="rtl"] .radio-inline,
html[dir="rtl"] .checkbox input,
html[dir="rtl"] .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

html[dir="rtl"] .list-group {
  padding-right: 0;
  padding-left: 40px;
}

html[dir="rtl"] .close {
  float: left;
}

html[dir="rtl"] .modal-header .close {
  margin: -15px auto -15px -15px;
}

html[dir="rtl"] .modal-footer > :not(:first-child) {
  margin-right: .25rem;
}

html[dir="rtl"] .alert-dismissible .close {
  right: inherit;
  left: 0;
}

html[dir="rtl"] .dropdown-toggle::after {
  margin-right: .255em;
  margin-left: 0;
}

html[dir="rtl"] .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

html[dir="rtl"] .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

html[dir="rtl"] .offset-1 {
  margin-right: 8.33333%;
  margin-left: 0;
}

html[dir="rtl"] .offset-2 {
  margin-right: 16.66667%;
  margin-left: 0;
}

html[dir="rtl"] .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

html[dir="rtl"] .offset-4 {
  margin-right: 33.33333%;
  margin-left: 0;
}

html[dir="rtl"] .offset-5 {
  margin-right: 41.66667%;
  margin-left: 0;
}

html[dir="rtl"] .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

html[dir="rtl"] .offset-7 {
  margin-right: 58.33333%;
  margin-left: 0;
}

html[dir="rtl"] .offset-8 {
  margin-right: 66.66667%;
  margin-left: 0;
}

html[dir="rtl"] .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

html[dir="rtl"] .offset-10 {
  margin-right: 83.33333%;
  margin-left: 0;
}

html[dir="rtl"] .offset-11 {
  margin-right: 91.66667%;
  margin-left: 0;
}

@media (min-width: 576px) {
  html[dir="rtl"] .offset-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-sm-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  html[dir="rtl"] .offset-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-md-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  html[dir="rtl"] .offset-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-lg-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  html[dir="rtl"] .offset-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }
  html[dir="rtl"] .offset-xl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

html[dir="rtl"] .mr-0,
html[dir="rtl"] .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

html[dir="rtl"] .ml-0,
html[dir="rtl"] .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

html[dir="rtl"] .mr-1,
html[dir="rtl"] .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

html[dir="rtl"] .ml-1,
html[dir="rtl"] .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}

html[dir="rtl"] .mr-2,
html[dir="rtl"] .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

html[dir="rtl"] .ml-2,
html[dir="rtl"] .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

html[dir="rtl"] .mr-3,
html[dir="rtl"] .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

html[dir="rtl"] .ml-3,
html[dir="rtl"] .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

html[dir="rtl"] .mr-4,
html[dir="rtl"] .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

html[dir="rtl"] .ml-4,
html[dir="rtl"] .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}

html[dir="rtl"] .mr-5,
html[dir="rtl"] .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

html[dir="rtl"] .ml-5,
html[dir="rtl"] .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}

html[dir="rtl"] .pr-0,
html[dir="rtl"] .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

html[dir="rtl"] .pl-0,
html[dir="rtl"] .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

html[dir="rtl"] .pr-1,
html[dir="rtl"] .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}

html[dir="rtl"] .pl-1,
html[dir="rtl"] .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}

html[dir="rtl"] .pr-2,
html[dir="rtl"] .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}

html[dir="rtl"] .pl-2,
html[dir="rtl"] .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

html[dir="rtl"] .pr-3,
html[dir="rtl"] .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

html[dir="rtl"] .pl-3,
html[dir="rtl"] .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

html[dir="rtl"] .pr-4,
html[dir="rtl"] .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

html[dir="rtl"] .pl-4,
html[dir="rtl"] .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

html[dir="rtl"] .pr-5,
html[dir="rtl"] .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}

html[dir="rtl"] .pl-5,
html[dir="rtl"] .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

html[dir="rtl"] .mr-auto,
html[dir="rtl"] .mx-auto {
  margin-right: 0;
  margin-left: auto;
}

html[dir="rtl"] .ml-auto,
html[dir="rtl"] .mx-auto {
  margin-right: auto;
  margin-left: 0;
}

@media (min-width: 576px) {
  html[dir="rtl"] .mr-sm-0,
  html[dir="rtl"] .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  html[dir="rtl"] .ml-sm-0,
  html[dir="rtl"] .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  html[dir="rtl"] .mr-sm-1,
  html[dir="rtl"] .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  html[dir="rtl"] .ml-sm-1,
  html[dir="rtl"] .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  html[dir="rtl"] .mr-sm-2,
  html[dir="rtl"] .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  html[dir="rtl"] .ml-sm-2,
  html[dir="rtl"] .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  html[dir="rtl"] .mr-sm-3,
  html[dir="rtl"] .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  html[dir="rtl"] .ml-sm-3,
  html[dir="rtl"] .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  html[dir="rtl"] .mr-sm-4,
  html[dir="rtl"] .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  html[dir="rtl"] .ml-sm-4,
  html[dir="rtl"] .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  html[dir="rtl"] .mr-sm-5,
  html[dir="rtl"] .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  html[dir="rtl"] .ml-sm-5,
  html[dir="rtl"] .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  html[dir="rtl"] .pr-sm-0,
  html[dir="rtl"] .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  html[dir="rtl"] .pl-sm-0,
  html[dir="rtl"] .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  html[dir="rtl"] .pr-sm-1,
  html[dir="rtl"] .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  html[dir="rtl"] .pl-sm-1,
  html[dir="rtl"] .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  html[dir="rtl"] .pr-sm-2,
  html[dir="rtl"] .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  html[dir="rtl"] .pl-sm-2,
  html[dir="rtl"] .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  html[dir="rtl"] .pr-sm-3,
  html[dir="rtl"] .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  html[dir="rtl"] .pl-sm-3,
  html[dir="rtl"] .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  html[dir="rtl"] .pr-sm-4,
  html[dir="rtl"] .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  html[dir="rtl"] .pl-sm-4,
  html[dir="rtl"] .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  html[dir="rtl"] .pr-sm-5,
  html[dir="rtl"] .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  html[dir="rtl"] .pl-sm-5,
  html[dir="rtl"] .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  html[dir="rtl"] .mr-sm-auto,
  html[dir="rtl"] .mx-sm-auto {
    margin-right: 0;
    margin-left: auto;
  }
  html[dir="rtl"] .ml-sm-auto,
  html[dir="rtl"] .mx-sm-auto {
    margin-right: auto;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  html[dir="rtl"] .mr-md-0,
  html[dir="rtl"] .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  html[dir="rtl"] .ml-md-0,
  html[dir="rtl"] .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  html[dir="rtl"] .mr-md-1,
  html[dir="rtl"] .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  html[dir="rtl"] .ml-md-1,
  html[dir="rtl"] .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  html[dir="rtl"] .mr-md-2,
  html[dir="rtl"] .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  html[dir="rtl"] .ml-md-2,
  html[dir="rtl"] .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  html[dir="rtl"] .mr-md-3,
  html[dir="rtl"] .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  html[dir="rtl"] .ml-md-3,
  html[dir="rtl"] .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  html[dir="rtl"] .mr-md-4,
  html[dir="rtl"] .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  html[dir="rtl"] .ml-md-4,
  html[dir="rtl"] .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  html[dir="rtl"] .mr-md-5,
  html[dir="rtl"] .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  html[dir="rtl"] .ml-md-5,
  html[dir="rtl"] .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  html[dir="rtl"] .pr-md-0,
  html[dir="rtl"] .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  html[dir="rtl"] .pl-md-0,
  html[dir="rtl"] .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  html[dir="rtl"] .pr-md-1,
  html[dir="rtl"] .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  html[dir="rtl"] .pl-md-1,
  html[dir="rtl"] .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  html[dir="rtl"] .pr-md-2,
  html[dir="rtl"] .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  html[dir="rtl"] .pl-md-2,
  html[dir="rtl"] .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  html[dir="rtl"] .pr-md-3,
  html[dir="rtl"] .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  html[dir="rtl"] .pl-md-3,
  html[dir="rtl"] .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  html[dir="rtl"] .pr-md-4,
  html[dir="rtl"] .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  html[dir="rtl"] .pl-md-4,
  html[dir="rtl"] .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  html[dir="rtl"] .pr-md-5,
  html[dir="rtl"] .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  html[dir="rtl"] .pl-md-5,
  html[dir="rtl"] .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  html[dir="rtl"] .mr-md-auto,
  html[dir="rtl"] .mx-md-auto {
    margin-right: 0;
    margin-left: auto;
  }
  html[dir="rtl"] .ml-md-auto,
  html[dir="rtl"] .mx-md-auto {
    margin-right: auto;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  html[dir="rtl"] .mr-lg-0,
  html[dir="rtl"] .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  html[dir="rtl"] .ml-lg-0,
  html[dir="rtl"] .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  html[dir="rtl"] .mr-lg-1,
  html[dir="rtl"] .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  html[dir="rtl"] .ml-lg-1,
  html[dir="rtl"] .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  html[dir="rtl"] .mr-lg-2,
  html[dir="rtl"] .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  html[dir="rtl"] .ml-lg-2,
  html[dir="rtl"] .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  html[dir="rtl"] .mr-lg-3,
  html[dir="rtl"] .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  html[dir="rtl"] .ml-lg-3,
  html[dir="rtl"] .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  html[dir="rtl"] .mr-lg-4,
  html[dir="rtl"] .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  html[dir="rtl"] .ml-lg-4,
  html[dir="rtl"] .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  html[dir="rtl"] .mr-lg-5,
  html[dir="rtl"] .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  html[dir="rtl"] .ml-lg-5,
  html[dir="rtl"] .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  html[dir="rtl"] .pr-lg-0,
  html[dir="rtl"] .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  html[dir="rtl"] .pl-lg-0,
  html[dir="rtl"] .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  html[dir="rtl"] .pr-lg-1,
  html[dir="rtl"] .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  html[dir="rtl"] .pl-lg-1,
  html[dir="rtl"] .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  html[dir="rtl"] .pr-lg-2,
  html[dir="rtl"] .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  html[dir="rtl"] .pl-lg-2,
  html[dir="rtl"] .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  html[dir="rtl"] .pr-lg-3,
  html[dir="rtl"] .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  html[dir="rtl"] .pl-lg-3,
  html[dir="rtl"] .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  html[dir="rtl"] .pr-lg-4,
  html[dir="rtl"] .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  html[dir="rtl"] .pl-lg-4,
  html[dir="rtl"] .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  html[dir="rtl"] .pr-lg-5,
  html[dir="rtl"] .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  html[dir="rtl"] .pl-lg-5,
  html[dir="rtl"] .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  html[dir="rtl"] .mr-lg-auto,
  html[dir="rtl"] .mx-lg-auto {
    margin-right: 0;
    margin-left: auto;
  }
  html[dir="rtl"] .ml-lg-auto,
  html[dir="rtl"] .mx-lg-auto {
    margin-right: auto;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  html[dir="rtl"] .mr-xl-0,
  html[dir="rtl"] .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  html[dir="rtl"] .ml-xl-0,
  html[dir="rtl"] .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  html[dir="rtl"] .mr-xl-1,
  html[dir="rtl"] .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  html[dir="rtl"] .ml-xl-1,
  html[dir="rtl"] .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  html[dir="rtl"] .mr-xl-2,
  html[dir="rtl"] .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  html[dir="rtl"] .ml-xl-2,
  html[dir="rtl"] .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  html[dir="rtl"] .mr-xl-3,
  html[dir="rtl"] .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  html[dir="rtl"] .ml-xl-3,
  html[dir="rtl"] .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  html[dir="rtl"] .mr-xl-4,
  html[dir="rtl"] .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  html[dir="rtl"] .ml-xl-4,
  html[dir="rtl"] .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  html[dir="rtl"] .mr-xl-5,
  html[dir="rtl"] .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  html[dir="rtl"] .ml-xl-5,
  html[dir="rtl"] .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  html[dir="rtl"] .pr-xl-0,
  html[dir="rtl"] .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  html[dir="rtl"] .pl-xl-0,
  html[dir="rtl"] .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  html[dir="rtl"] .pr-xl-1,
  html[dir="rtl"] .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  html[dir="rtl"] .pl-xl-1,
  html[dir="rtl"] .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  html[dir="rtl"] .pr-xl-2,
  html[dir="rtl"] .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  html[dir="rtl"] .pl-xl-2,
  html[dir="rtl"] .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  html[dir="rtl"] .pr-xl-3,
  html[dir="rtl"] .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  html[dir="rtl"] .pl-xl-3,
  html[dir="rtl"] .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  html[dir="rtl"] .pr-xl-4,
  html[dir="rtl"] .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  html[dir="rtl"] .pl-xl-4,
  html[dir="rtl"] .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  html[dir="rtl"] .pr-xl-5,
  html[dir="rtl"] .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  html[dir="rtl"] .pl-xl-5,
  html[dir="rtl"] .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  html[dir="rtl"] .mr-xl-auto,
  html[dir="rtl"] .mx-xl-auto {
    margin-right: 0;
    margin-left: auto;
  }
  html[dir="rtl"] .ml-xl-auto,
  html[dir="rtl"] .mx-xl-auto {
    margin-right: auto;
    margin-left: 0;
  }
}

html[dir="rtl"] body {
  text-align: right;
}

html[dir="rtl"] .sidebar {
  left: unset;
  right: 0;
}

html[dir="rtl"] .sidebar .nav i {
  float: right;
  margin-left: 15px;
  margin-right: unset;
}

html[dir="rtl"] .main-panel {
  float: left;
}

html[dir="rtl"] .card .card-header.card-header-tabs .nav-tabs-title {
  float: right;
  padding: 10px 0 10px 10px;
}

html[dir="rtl"] .card.card-chart {
  direction: ltr;
}

html[dir="rtl"] .nav-tabs .nav-item .material-icons {
  margin: -1px 0 0 5px;
}

html[dir="rtl"] .form-check {
  direction: ltr;
}

html[dir="rtl"] .navbar .navbar-nav .dropdown-menu-right {
  transform-origin: 0 0;
}

html[dir="rtl"] .bmd-form-group [class^='bmd-label'].bmd-label-floating,
html[dir="rtl"] .bmd-form-group [class*=' bmd-label'].bmd-label-floating {
  will-change: right, top, contents;
}

html[dir="rtl"] .is-focused .bmd-label-floating,
html[dir="rtl"] .is-filled .bmd-label-floating {
  right: 0;
  left: unset;
}

html[dir="rtl"] .alert.alert-with-icon {
  padding-right: 66px;
  padding-left: 15px;
}

html[dir="rtl"] .alert.alert-with-icon i[data-notify="icon"] {
  right: 15px;
  left: unset;
}

html[dir="rtl"] .alert button.close {
  left: 10px !important;
  right: unset !important;
}

@media (min-width: 992px) {
  html[dir="rtl"] .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    left: 0;
    right: auto;
  }
}

html[dir="rtl"] .sidebar .sidebar-wrapper .navbar-form .input-group {
  padding-right: 17px;
  padding-left: unset;
}

html[dir="rtl"] .sidebar,
html[dir="rtl"] .off-canvas-sidebar nav .navbar-collapse {
  text-align: right;
}

html[dir="rtl"] .card .card-body, html[dir="rtl"] .card .card-footer {
  direction: rtl;
}

@media (max-width: 991px) {
  html[dir="rtl"] .navbar-form .btn {
    left: -50px;
    right: unset;
  }
}

@media (min-width: 992px) {
  html[dir="rtl"] .navbar-expand-lg > .container,
  html[dir="rtl"] .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
}

@media (max-width: 991px) {
  html[dir="rtl"] [class*="navbar-expand-"] > .container,
  html[dir="rtl"] [class*="navbar-expand-"] > .container-fluid {
    flex-direction: row-reverse;
  }
}
/*# sourceMappingURL=material-dashboard-rtl.css.map */